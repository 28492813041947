<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
            :headers="campaignHeaders"
            :items="campaigns"
            :loading="loading || templateLoading"
            :search="campaignSearch"
        >
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col>
                <v-btn color="primary" small @click="toggleCustomerDialog(item)">Link Customers</v-btn>
              </v-col>
              <v-col>
                <v-btn color="secondary" small @click="createPDF(item)">Export {{ item.title }}</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.customers="{ item }">
                {{item.customers.map((t) => t.name).join(", ")}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>New Physical Mail Campaign</v-card-title>
          <v-card-subtitle><v-btn color="secondary" @click="showForm = !showForm">{{ !showForm ? "Show Form" : "Hide Form" }}</v-btn></v-card-subtitle>
          <v-card-text v-if="showForm">
            <v-row>
              <v-col>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="insertCampaign.title"
                        label="Campaign Title"
                    >

                    </v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="insertCampaign.customaftertitle"
                        label="Text After Title"
                    >

                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-textarea
                        v-model="insertCampaign.body"
                        label="Body"
                    >

                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                        v-model="insertCampaign.customfooter"
                        label="Footer"
                    >
                    </v-text-field>
                    <v-select
                        v-model="insertCampaign.skill_id"
                        :items="skillList"
                        item-text="title"
                        item-value="id"
                        label="Skill For This Campaign"
                        hint="Select the skill applicable to this Physical Mail Campaign"
                        persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="insertCampaign.from_name"
                        label="From Title"
                        hint="ex. Bill Gates"
                        persistent-hint
                    >

                    </v-text-field>
                    <v-text-field
                        v-model="insertCampaign.from_title"
                        label="From Title"
                        hint="ex. Co-Founder, CEO, Recruitment Lead"
                        persistent-hint
                    >

                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-switch
                        v-model="insertCampaign.ready_for_print"
                        label="Ready For Print"
                    >

                    </v-switch>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-switch
                        v-model="insertCampaign.printed"
                        label="Printed"
                    >

                    </v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                      md="12"
                  >
                    <v-textarea
                        v-model="insertCampaign.internal_note"
                        label="Internal Note"
                    >

                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="postData">Add</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="customerDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title>Link Customers</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                  v-model="customersSelected"
                  :items="customers"
                  item-text="name"
                  item-value="id"
                  label="Customers"
                  multiple
                  chips
                  hint="You may select multiple customers to add to this campaign."
                  persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" @click="putDataCustomers(customerDialogCampaignData)">Add</v-btn>
            </v-col>
            <v-col>
              <v-btn color="secondary" @click="toggleCustomerDialog(null)">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";

export default {
name: "PhysicalMail",
  mounted() {
    this.getPhysicalMailCampaigns()
    this.getSkills()
    this.getTemplate()
    this.getCustomers()
  },

  data() {
    return {
      campaignHeaders: [
        { text: "ID", value: "id" },
        { text: "campaign title", value: "title" },
        { text: "body", value: "body" },
        { text: "text after title", value: "customaftertitle" },
        { text: "footer", value: "customfooter" },
        { text: "skill", value: "skill.title" },
        { text: "from name", value: "from_name" },
        { text: "from title", value: "from_title" },
        { text: "customers", value: "customers" },
        { text: "ready for print", value: "ready_for_print" },
        { text: "printed", value: "printed" },
        { text: "internal note", value: "internal_note" },
        { text: "actions", value: "actions"}
      ],
      campaigns: [],
      campaignSearch: "",
      selectedItems: null,
      loading: true,
      insertCampaign: {
        title: null,
        body: null,
        customaftertitle: null,
        customfooter: null,
        skill_id: null,
        from_name: null,
        from_title: null,
        ready_for_print: false,
        printed: false,
        internal_note: null
      },
      skillList: [],
      showForm: false,
      template: {},
      templateLoading: true,
      customers: [],
      customerDialog: false,
      customerDialogCampaignData: {},
      customersSelected: [],
    };
  },

  methods: {
    toggleCustomerDialog(campaignData) {
      this.customerDialogCampaignData = campaignData
      this.customersSelected = []
      this.customerDialog = !this.customerDialog
    },

    createPDF(campaign) {
      //const WorkSansRegular = import("/src/assets/fonts/WorkSans-Regular-normal")

      let pdf = new jsPDF("p", "mm", "a4")
      // Title
      pdf.setFontSize(24);
      pdf.setFont("Helvetica");
      pdf.setTextColor("#215FF6")
      pdf.text(`Title: ${campaign.title}`, 25, 50)

      pdf.setFontSize(14);
      pdf.setTextColor("#000000")
      pdf.text(`After Title: ${campaign.customaftertitle}`, 25, 80)

      pdf.text(`Body: ${campaign.body}`, 25, 120)

      pdf.text(`Footer: ${campaign.customfooter}`, 25, 180)

      pdf.setFontSize(24);
      pdf.setTextColor("#215FF6")
      pdf.text(`Best,`, 25, 200)

      pdf.setFontSize(14);
      pdf.setTextColor("#000000")
      pdf.text(`From Name: ${campaign.from_name}`, 25, 226)
      pdf.text(`From Name: ${campaign.from_title}`, 25, 236)
      pdf.text(`SlashDev, slashdev.io`, 25, 246)

      pdf.save(campaign.title + "-physical-mail-campaign-example.pdf")
    },

    getTemplate() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/physical-mail-template/1`, this.$store.state.headers)
          .then(function (response) {
            self.template = response.data
            self.insertCampaign.title = response.data.title
            self.insertCampaign.body = response.data.body
            self.insertCampaign.customaftertitle = response.data.customaftertitle
            self.insertCampaign.customfooter = response.data.customfooter
            self.templateLoading = false
          });
    },

    postData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/physical-mail`, self.insertCampaign, this.$store.state.headers)
            .then(function (response) {
              console.log(response.data);
              self.getPhysicalMailCampaigns()
              alert("success")
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    },

    putDataCustomers(campaign) {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/physical-mail/${campaign.id}`, {customers: self.customersSelected}, this.$store.state.headers)
            .then(function (response) {
              console.log(response.data);
              self.getPhysicalMailCampaigns()
              self.toggleCustomerDialog()
            })
            .catch(function (response) {
              alert(response.response.data.errors.messages)
            })
      });
    },

    async getPhysicalMailCampaigns() {
      axios
          .get(`${this.$store.state.apiUrl}/api/physical-mail`, this.$store.state.headers)
          .then((response) => {
            this.campaigns = response.data;
            this.loading = false;
          })
          .catch((error) => (this.campaigns = error.response.data.errors));
    },

    async getSkills() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/skills`, this.$store.state.headers)
          .then(function (response) {
            self.skillList = response.data
          });
    },

    async getCustomers() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/customers`, this.$store.state.headers)
          .then(function (response) {
            self.customers = response.data
          });
    },
  }
}
</script>

<style scoped>

</style>
